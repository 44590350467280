import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadFilterOperatorsUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { FilterOperator, FilterOperatorResponse } from '../types';

export const loadFilterOperators = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: FilterOperator[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const url = buildLoadFilterOperatorsUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<FilterOperatorResponse>) => {
            const { FilterOperators } = response;
            const result = convertToCamel<FilterOperator[]>(FilterOperators);
            return observableOf({ result, actionName, processResultFunction });
        }),
    );
};
